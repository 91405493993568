html {
    font-size: 62.5%;
    box-sizing: border-box;
    &::-webkit-scrollbar {
        width: 8px;               /* width of the entire scrollbar */
    }
      
    &::-webkit-scrollbar-track {
        background: rgba(66, 71, 77, 0.09);        /* color of the tracking area */
    }
      
    &::-webkit-scrollbar-thumb {
        background-color: rgba(70, 99, 126, 0.556);    /* color of the scroll thumb */
        border-radius: 20px;       /* roundness of the scroll thumb */
        border: 3px solid rgba(66, 71, 77, 0.09);  /* creates padding around scroll thumb */
    }
    &:focus {
        outline: none;
    }
}
*, *:before, *:after {
    box-sizing: inherit;
}

body {
    font-family: $fuentePrincipal;
    font-size: 1.6rem;
    line-height: 1.8;
    background-color: $fondo;
}
.contenedor {
    width: 85%;
    max-width: 1000px;
    margin: 0 auto;
}

a {
    text-decoration: none;
    color: $blue;
    &:hover {
        color: lighten($blue, 15)
    }
}
p {
    color: $colorFuente;
    text-align: justify;
    font-size: 1.4rem;
}

img,
picture {
    width: 100%;
    display: block;
}

h1, h2, h3 {
    font-family: $fuentePrincipal;
    margin: 2.5 0;
    font-weight: 300;
    text-align: center;
    color: $colorFuente;
    
}

pre {
    color: $colorFuente;
    overflow: overlay;
    &::-webkit-scrollbar {
        width: 8px;               /* width of the entire scrollbar */
    }
      
    &::-webkit-scrollbar-track {
        background: rgba(66, 71, 77, 0.09);        /* color of the tracking area */
    }
      
    &::-webkit-scrollbar-thumb {
        background-color: rgba(70, 99, 126, 0.556);    /* color of the scroll thumb */
        border-radius: 20px;       /* roundness of the scroll thumb */
        border: 3px solid rgba(66, 71, 77, 0.09);  /* creates padding around scroll thumb */
    }
}

h1 {
    font-size: 3.8rem;
}
h2 {
    font-size: 3.4rem;
}
h3 {
    font-size: 3rem;
}
h4 {
    font-size: 2.6rem;
}

hr {
    border: 1px solid rgba(200,200,200, .6);
}
h2 small {
    font-size: 1.6rem;
}

ul {
    padding-left: 0;
}
li {
    list-style: none;
}

.page-not-found {
    display: flex;
    height: 600px;
    align-items: center;
    justify-content: center;
}

.centrado {
    text-align: center;
}
.alinear-derecha {
    text-align: right;
}
.alinear-izquierda {
    text-align: left;
}

table {
    color: $colorFuente;
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 auto 40px auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
    th, td, caption {
        min-width: 110px;
        padding: 1vh 2vw;
    }
    caption {
        font-weight: bold;
        background-color: $fondoHeader;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }
    th {
        background-color: rgb(49, 57, 65);
        &:first-child {
            text-align: left;
        }
    }
    tr:nth-child(odd) {
        background-color: rgba(77, 94, 111, 0.371);
    }
    tr:nth-child(even) {
        background-color: rgba(77, 94, 111, 0.289);
    }
    tr:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        overflow: hidden;
    }
    a {
        color: rgb(112, 145, 167);
        &:hover {
            text-decoration: underline;
        }
    }
    @media (min-width: 768px) {
        &:last-child {
            margin-right: 20px;
        }
    }
}
@media (min-width: 768px) {
    .dos-columnas-en-tablet {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .dos-columnas-en-tablet-flex {
        display: flex;
        flex-direction: row-reverse;
    }
}

/* Hacer que el footer esté abajo aún cuando la altura de la página sea pequeña */
html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    display: table;
}

footer {
    display: table-row;
    div {
        margin: 10px auto;
    }
}

/* videos youtube */
.enlaces-videos-youtube {
    .iframe-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
        p {
            text-align: center
        };
        iframe {
            padding: 5px;
            max-width: 80vw;
            max-height: 25vh;
        }
    }
    @media (min-width: 768px) {
        display: flex;
        justify-content: center;
        
        flex-wrap: wrap;
        .iframe-container {
            justify-content: center;
            width: 50%;
            padding-right: 20px;
            margin: 0;
            iframe {
                max-width: 25vw;
                max-height: 25vh;
            }
        }
    }
}