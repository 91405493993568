section {
    .video-loop { 
        min-height: 200px;
        max-height: 600px;
        margin: 0;
        position: relative;
        overflow: hidden;
        video {
            width: 100%;
            object-fit: fill;
            filter: opacity(0.5) hue-rotate(226deg);
        }
    }
    .buttons-over-video {
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom: 30%;
        left: 10%;
        @media (min-width: 768px) {
            flex-direction: row;
            bottom: 40%;
            left: 15%;
        }
        a {
            text-shadow: 1px 1px 2px black;
            border: 1px solid rgba(255, 255, 255, .2);
            border-radius: 10px;
            align-items: center;
            justify-content: space-between;
            &:first-child {
                margin: 0 0 5vw 0;
            }
            .portada4 {
                padding: 2vw;
                width: 15vw;
            }
            .iconos {
                padding: 0 10px;
                width: 40px;
                filter: grayscale(100%);
            }
            &:hover .iconos {
                transform: scale(1.1);
                filter: grayscale(0%);
            }
                &:hover {
                    transform: scale(1.2); 
                box-shadow: 2px 2px 20px rgba(237, 237, 237, 0.474);
            }
            p{
                font-size: 3.5vw;
            }
            display:flex;
            @media (min-width: 768px) { 
                justify-content: center;
                align-items: center;
                width: 25vw;
                &:first-child {
                    margin: 0 20vw 0 0;
                }
                .portada4 {
                    padding: 0 15px;
                    width: 8vw;
                }
                .iconos {
                    padding: 0 10px;
                    width: 4.5vw;
                }
                &:hover {
                    transform: scale(1.2); 
                }
                p{
                    font-size: 1.5vw;
                }
            }
        }
    }
}

@media (min-width: 768px) {
    p {
        font-size: 1.8rem;
    }
}
.seccion-covers { 
    a {
        text-align: center;
        display: block;
        max-width: 400px;
        margin: 0 auto 30px auto;
        
    }
    p {
        text-align: center;
    }
    margin-bottom: 30px;
}