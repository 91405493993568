.seccion-albumes p {
    text-align: center;
}
.albumes {
    .lista-albumes {
        
        border: 1px solid rgba(200, 200, 200, .5);
        border-radius: 5px;
        margin: 0 0 10px 0;
        display: flex;
        justify-content: space-between;
        &:last-child {
            margin: 0;
        }
        &:hover {
            transform: scale(1.1);
            background-color: $fondo;
            box-shadow: 2px 2px 20px rgba(237, 237, 237, 0.474);
        }
    }
    div {
        display: flex;
    }
    a{
        display: flex;
        color: $colorFuente;
        align-items: center;
        font-size: 2rem;
        padding: 10px;
        width: 75%;
        figcaption {
            display: flex;
            flex-direction: column;
        }
        small {
            font-size: 1.2rem;
        }
        
    }
    .portada {
        max-width: 90px;
        margin: 0 15px 0 0;
    }
    .icono-escucha {
        img {
            width: 100%;
            filter: grayscale(100%);
            &:hover {
                transform: scale(1.1);
                filter: grayscale(0%);
            }
        }
        @media (min-width: 768px) {
            align-items: center;
        }
    }
}
.enlaces-escuchar {
    display: flex;
    justify-content: center;
    img {
        max-width: 30px;
        margin: 20px;
        filter: grayscale(100%);
        &:hover {
            transform: scale(1.1);
            filter: grayscale(0%);
        }
    }
}

.enlaces-escuchar-cajas {
    display: flex;
    justify-content: center;
    flex-direction: column;
    img {
        max-width: 40px;
        filter: grayscale(100%);
        &:hover {
            transform: scale(1.1);
            filter: grayscale(0%);
        }
    }
    @media (min-width: 768px) {
        flex-direction: row;
        img.youtube {
            margin: 20px;
        }
    }
}
.columnas-albumes {
    padding-left: 0;
    figcaption {
        line-height: 1.4;
    }
}

@media (min-width: 768px) {
    .albumes .lista-albumes {
        &:nth-child(odd) {
            margin-right: 10px;
        }
    }
    .columnas-albumes {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

table {
    .enlaces-escuchar {
        display: flex;
        justify-content: space-around;
        img {
            filter: grayscale(100%);
            &:hover {
                transform: scale(1.1);
                filter: grayscale(0%);
            }
        }
    }
}


/* ALBUMES */

.portada {
    max-width: 300px;
    margin: 0 auto;
}

.canciones {
    ol { 
        counter-reset: item; 
        padding-left: 10vw;
        li { 
            display: block; 
            &:before {
                content: counter(item) ". ";
                counter-increment: item;
                color: $colorFuente;
            }
        }
    }
}



.album-info {
    h3 {
        margin-top: 0px;
    }
}

/* DESCRIPCION CANCION */

.cancion .portada {
    max-width: 150px;
}