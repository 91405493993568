/* PUBLICACIONES */
.opciones-publicaciones{
    li {
        
        a {
            display: flex;
            justify-content: center;
            color: $colorFuente;
            font-size: 2rem;
            padding: 30px 0;
            @media (min-width: 768px) {
                 padding: 30px;
            }
            img {
                margin-right: 10px;
                max-width: 30px;
            }
        }   
        &:hover a{
            transform: scale(1.1);
            text-shadow: 1px 1px 20px $blue;
        }
    }
}

    /* icono al lado del título */
h1 {
    display: flex;
    justify-content: center;
    img {
    max-width: 50px;
    padding-right: 10px;
    }
}
    /* contenedor de videos youtube */
.enlaces-videos-youtube-little {
    .iframe-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        p {
            text-align: center
        };
        iframe {
            padding: 5px;
            max-width: 40vw;
            max-height: 15vh;
        }
    }
    @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        
        flex-wrap: wrap;
        .iframe-container {
            justify-content: center;
            width: 50%;
            padding-right: 20px;
            margin: 0;
        }
        iframe {
            max-width: 20vw;
            max-height: 15vh;
        }
    }
}

/* PENSAMIENTOS DISPERSOS */
.pensamiento-disperso {
    cursor: pointer;
    p{
        height: 0;
        visibility: hidden;
    }
}

.pensamiento-disperso.ver-parrafos {
    cursor: pointer;
    p{
        height: auto;
        visibility: visible;
    }
}

/* OTROS PROYECTOS */




.muestras-audio {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

/* ISSY REVERB */