/* HEADER */
header {
    display: grid;
    background-color: $fondoHeader;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    div {
        z-index: 1;
        display: flex;
        justify-content: space-between;
        img {
            max-width: 200px;
            padding: 5px 0 2px 10px;
        }
    }
    @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
    }
    nav {
        grid-column: 3/1;
        ul {
            display: flex;
            flex-direction: column;
        }
        a, a:hover {
            color: $colorFuente;
        }
        a {
            font-family: $fuenteHeader;
            letter-spacing: 2px;
            text-transform: uppercase;
            font-size: 2rem;
            padding: 15px 15px 8px 15px;
            display: block;
            border-radius: 2px;
            text-align: center;
            &:hover {
                background-color: $blue;
            }
        }
        @media (min-width: 768px) {
            padding: 0 25px 0 0;
            ul {
                display: flex;
                flex-direction: row;
                margin: 8px 0 0 0;
            }
            a{
                text-align: left;
            }
        }
    }
    span { 
        background-color: transparent;
        border: none;
        margin: auto 8px auto;
        padding: 0;
        img {
            width: 60px;
            margin: 0;
            padding: 15px;
            &:hover {
                cursor: pointer;
            }
        }
    }
}
@media (max-width: 768px) {
    .menu-desplegable {
        background-color: $fondoHeader;
        position: fixed;
        width: 100%;
        top: 75px;
        right: -100vw;
        transition: all .4s ease-in-out;
    }
    nav.mostrar-menu {
        right: 0;
    }
}    

.under-header {
    background-color: white;
    height: 75px;
}
.ocultar-en-mayor-768px {
    display: block;
    @media (min-width: 768px) {
         display: none;
    }
}
.tab-selected {
    background-color: darken($blue, 5);
}

/* FOOTER */

// .under-footer {
//     background-color: white;
//     height: 75px;
// }

footer{
    background-color: $fondoHeader;
    padding: 30px 0 10px 0;
    
    p {
        text-align: center;
        font-size: 1.5rem;
        margin: 40px 0 0 0;
    }
    .footer-columns {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        a {
            padding: 10px;
            border: 2px solid rgba(42, 120, 203, .5);
            border-radius: 5px;
            margin: 30px 0;
            &:hover {
                background-color: lighten($fondoHeader,20);
                border-color: lighten($fondoHeader,20);
            }
        }
        .social-networks {
            .links-social-networks {
                display: flex;
            }
            h6 {
                margin: 0 0 25px 0;
                font-family: $fuenteHeader;
                text-transform: uppercase;
                letter-spacing: 2px;
                color: $colorFuente;
                text-align: center;
                font-size: 2rem;
            }
            img {
                max-width: 30px;
                fill: $colorFuente;
            }
            a {
                padding: 10px;
                margin: 0 10px;
                background-color: #EEE;
                border-radius: 50%;
                display: flex;
                align-items: center;
                &:hover {
                    transform: scale(1.5);
                    box-shadow: 2px 2px 30px black;
                }
            }
        }
    
    }
    @media (min-width: 768px) {
        .footer-columns {
            flex-direction: row;
            .social-networks {
                .links-social-networks {
                    h6 {
                        margin: 0;
                    }
                    a {
                        margin-left: 20px;
                        background-color: #EEE;
                        border-radius: 50%;
                    }
                }
        
            }
        }
    }
}