@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&family=Roboto:wght@300;400;500;700&family=Teko:wght@400;500&display=swap');

// Fuentes
$fuentePrincipal: 'Roboto', sans-serif;
$fuenteHeader: 'Teko', sans-serif;
// Colores
$colorFuente: #999;
$fondo: #03070A;
$fondoHeader: #17222E;
$blue: #2A78CB;
$tabsRgb: 42, 120, 203;
